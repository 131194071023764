<template>
  <div>
    <div class="position-relative">
      <div :class="{'non-clickable':actionTypeCheck}" />
      <vue-dropzone
        id="dropzone"
        ref="myVueDropzone"
        :duplicate-check="true"
        :options="dropzoneOptions"
        :class="actionTypeCheck ? 'border-0' : ''"
        @vdropzone-success="handleFileAdding"
        @vdropzone-removed-file="removeFile"
        @vdropzone-error="handleError"
        @vdropzone-duplicate-file="handleDuplicateFile"
        @vdropzone-sending="checkExistFile"
      />
      <small
        v-if="!isValidFile"
        class="text-danger"
      >{{
        FileErrorMsg
      }}</small>
      <div
        v-show="
          addIconShow && dropzoneMaxFiles > 1 &&
            !actionTypeCheck &&
            errorFileList.length == 0
        "
        ref="more"
        class="more"
      >
        +
      </div>
    </div>
    <div
      v-if="manualFileAdded && manualFileAdded.length"
      class="mb-1 download-content"
      style=""
    >
      <div class="download-files">
        <div
          v-for="(image, key) in manualFileAdded"
          :key="key"
          class="download-link p-50"
          role="button"
          @click="openFileInNewTab(image.url)"
        >
          {{ image.name }}
          <feather-icon
            icon="EyeIcon"
            color="white"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'FileUpload',
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    actionTypeCheck: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptedFiles: {
      type: String,
      required: false,
      default: '.jpeg, .jpg, .png, .txt, .pdf, .ppt,.pptx, .doc, .csv, .docx, .xls, .xlsx',
    },
    manualFileAdded: {
      type: Array,
      required: false,
      default: null,
    },
    dropzoneUrl: {
      type: String,
      default: `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/project/task/image-store`,
    },
    dropzoneMaxFiles: {
      type: Number,
      default: 20,
    },
    dropzoneUploadMultiple: {
      type: Boolean,
      default: true,
    },
    dropzoneParallelUploads: {
      type: Number,
      default: 100,
    },
    dropzoneMaxFilesize: {
      type: Number,
      default: 10,
    },
    dropzoneAddRemoveLinks: {
      type: Boolean,
      default: true,
    },
    dropzoneDictDefaultMessage: {
      type: String,
      default: "<b class='drop-title'>Drag & drop files</b> <br> <p class='drop-req'>Supported formats: JPEG, PNG, JPG, <br /> TXT, PDF, PPT, DOC, CSV, DOCX, XLS, XLSX <br /> ",
    },
    isFilerequired: {
      type: Boolean,
      default: false,
    },
    fileRequiredMessage: {
      type: String,
      default: 'xyz',
    },
  },
  data() {
    return {
      loader: false,
      file: [],
      remove_attachments: [],
      addIconShow: false,
      errorFileList: [],
      isValidFile: true,
      FileErrorMsg: null,
    }
  },
  computed: {

    dropzoneOptions() {
      return {
        url: this.dropzoneUrl,
        maxFiles: this.dropzoneMaxFiles,
        uploadMultiple: this.dropzoneUploadMultiple,
        parallelUploads: this.dropzoneParallelUploads,
        maxFilesize: this.dropzoneMaxFilesize,
        addRemoveLinks: this.dropzoneAddRemoveLinks,
        acceptedFiles: this.acceptedFiles,
        dictDefaultMessage: this.dropzoneDictDefaultMessage,
      }
    },
  },
  watch: {
    file(nv) {
      if (nv) {
        const obj = {
          file: this.file,
          removeAttachments: this.remove_attachments,
          isValidFile: this.isValidFile,
        }
        this.$emit('onChange', obj)
      }
    },
    remove_attachments() {
      this.$emit('onFileRemove', this.remove_attachments)
    },
    isValidFile(nv) {
      if (nv) {
        const dropzone = this.$refs.myVueDropzone
        console.log('dropzone: ', dropzone)
      }
    },
  },
  mounted() {
    /** Add Manual File on Edit Data */
    if (this.manualFileAdded && this.manualFileAdded.length) {
      this.manualFileAdded.forEach(attachment => {
        const file = {
          size: 150,
          name: `${attachment.name}`,
          type: `${attachment.type}`,
          id: `${attachment.id}`,
          status: 'manuallyAdded',
        }

        this.openFileInNewTab(attachment.url, false).then(response => {
          if (!this.actionTypeCheck) {
            this.$refs.myVueDropzone.manuallyAddFile(file, response)
            this.handleMoreThumbnail()
          }
        })
      })
    }
  },
  methods: {
    parseAcceptedFiles(acceptedFiles) {
      const formats = acceptedFiles.split(',').map(format => format.trim().replace('.', '').toUpperCase())
      return formats.filter(format => format !== '')
    },
    handleMoreThumbnail() {
      const { dropzone } = this.$refs.myVueDropzone
      dropzone.files.length > 0 && dropzone.files.length < 20
        ? (this.addIconShow = true)
        : (this.addIconShow = false)
      dropzone.files.length > 0
        ? dropzone.element.appendChild(this.$refs.more)
        : dropzone.element.removeChild(this.$refs.more)
    },

    handleFileAdding(file) {
      this.handleMoreThumbnail()
      this.isValidFile = true
      this.file.push(file)
    },

    removeFile(file) {
      // console.log("remove File :",file);
      if (file.id) {
        this.remove_attachments.push(file.id)
        const taskAttachmentsIndex = this.manualFileAdded.findIndex(
          x => x.id === file.id,
        )
        this.manualFileAdded.splice(
          taskAttachmentsIndex,
          1,
        )
      } else if (file && file.status && file.status !== 'added' && file.status !== 'error') {
        const fileIndex = this.file.findIndex(
          x => x.upload.uuid === file.upload.uuid,
        )
        this.file.splice(fileIndex, 1)
      }

      const ErrorFileIndex = this.errorFileList.findIndex(
        x => x.upload.uuid === file.upload.uuid,
      )
      this.errorFileList.splice(ErrorFileIndex, 1)

      this.isValidFile = !(this.errorFileList && this.errorFileList.length)
      this.manualFileAdded.length > 0
        ? (this.addIconShow = true)
        : (this.addIconShow = false)
      this.handleMoreThumbnail()
    },

    // use for validation only
    handleError(file, message) {
      this.isValidFile = file.accepted
      this.FileErrorMsg = message
      this.errorFileList.push(file)
    },

    // Check on duplicate file when task create
    handleDuplicateFile() {
      this.isValidFile = false
      this.FileErrorMsg = 'Duplicate file does not allowed.'
    },

    // Check on duplicate file when task update
    checkExistFile(file) {
      if (this.manualFileAdded.length) {
        const fileExit = this.manualFileAdded.find(item => item.name === file.name)
        if (fileExit) {
          this.$refs.myVueDropzone.removeFile(file)
          this.isValidFile = false
          this.FileErrorMsg = 'Duplicate file does not allowed.'
        }
      }
    },
  },
}
</script>

<style>
.my-file-up .dropzone .dz-preview.dz-error .dz-error-message {
  opacity: 0 !important;
  pointer-events: auto;
}
.more {
  display: inline-block;
  margin: 16px;
  border: 3px dashed lightgray;
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  color: lightgray;
  border-radius: 8px;
  font-size: 60px;
  text-align: center;
  line-height: 200px;
  pointer-events: none;
}
</style>
